<template>
  <div
    id="user-binding"
  >
    <b-card
      v-if="!checkOTPState"
      class="mb-0"
    >
      <h2 class="text-primary text-center">
        <strong>{{ t('title.Hospital Connect Title') }}</strong>
      </h2>
      <b-card-text class="mb-2 text-center">
        <h5>{{ t('title.Hospital Connect Info') }}</h5>
        <!-- already have an account? Sign In -->
      </b-card-text>
      <b-row class="flex justify-content-center">
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="ID Card / Passport No."
              rules="required"
            >
              <label>{{ t('patient.idCard') }}, {{ t('patient.passport') }}</label>
              <b-input-group>
                <b-form-input
                  v-model="idCardValue"
                  :state="errors.length > 0 ? false:null"
                  placeholder="ID Card or Passport No"
                  autocomplete="OFF"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="getPatientInfo"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <validation-observer ref="bindingForm">
        <b-form>
          <!-- checkProfile -->
          <div
            v-if="checkProfile"
            id="profile-details"
          >
            <b-card
              border-variant="primary"
              bg-variant="transparent"
              class="shadow-none"
            >
              <b-card-text>
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <strong>{{ t('patient.hn') }} <h6 class="text-primary"> {{ hnValue }}</h6></strong>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <strong>{{ t('patient.name') }} <h6 class="text-primary"> {{ ptName }}</h6></strong>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
            <small class="text-center">
              {{ t('When u click') }} "{{ t('Button Connect Customer') }}" {{ t('Connect HN') }} {{ t('Accept') }}
              <b-link v-b-modal.termsAndConditionsModal>{{ t('Privacy Policy') }}</b-link> {{ t('for Hospital') }}
            </small>
            <b-row class="mt-2">
              <b-col
                cols="12"
                md="6"
                class="text-center mb-1"
              >
                <b-button
                  block
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  {{ t('Button Connect Customer') }}
                </b-button>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-button
                  block
                  variant="dark"
                  type="button"
                  @click="$router.push({ name: 'member-account' })"
                >
                  {{ t('Button Cancel') }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card v-else>
      <validation-observer
        ref="otpForm"
      >
        <h2 class="text-primary text-center">
          <strong class="text-success">OTP</strong>
        </h2>
        <h5 class="text-center">
          {{ t('title.OTP fill') }}
        </h5>
        <h2 class="mt-2 mb-2 text-center">
          <strong>
            ❝ {{ ptPhone.substring(0, 3) }}-{{ ptPhone.substring(3, 6) }}-{{ ptPhone.substring(6) }} ❞
          </strong>
        </h2>
        <b-card-text class="text-center">

          <v-otp-input
            class="flex justify-content-center"
            input-classes="otp-input"
            :num-inputs="6"
            separator=""
            :should-auto-focus="true"
            @on-complete="handleOnComplete"
            @on-change="handleOnChange"
          />

          <h6 class="mt-3">
            {{ t('title.OTP question') }}
          </h6>
          <h6 v-if="countDown>0">
            {{ countDown }}
            <b-spinner
              small
              variant="primary"
            />
          </h6>
          <b-button
            variant="link"
            :disabled="countDown>0"
            @click="sendOTP"
          >
            {{ t('title.OTP resend') }}
          </b-button>
          <hr>
          <b-button
            variant="primary"
            block
            type="submit"
            :disabled="validationState"
            @click.prevent="validationOTP"
          >
            <b-spinner
              v-if="validationState"
              small
            />
            {{ t('Button Save') }}
          </b-button>
        </b-card-text>
      </validation-observer>
    </b-card>
    <b-modal
      id="termsAndConditionsModal"
      hide-header
      hide-footer
      size="xl"
      scrollable
      no-close-on-backdrop
    >
      <terms-and-conditions @consent="closeConsent" />
    </b-modal>
  </div>

</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import TermsAndConditions from '../authentication/TermsAndConditions.vue'
import OtpInput from '@bachdgvn/vue-otp-input'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    TermsAndConditions,
    'v-otp-input': OtpInput,
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      ptName: '',
      emailValue: '',
      hnValue: '',
      idCardValue: '',
      required,
      email,
      userData: JSON.parse(localStorage.getItem('userData')),
      accountLoginData: JSON.parse(localStorage.getItem('accountLogin')),
      checkProfile: false,
      customerID: 0,
      patientData: [],
      ptPhone: '',
      checkOTPState: false,
      token: '',
      countDown: 60,
      validationState: false,
      OPTValue: 0,
    }
  },
  mounted() {
    // this.emailValue = this.userData.email
    this.$bvModal.show('termsAndConditionsModal')
  },
  created() {
    this.countDownTimer()
  },
  methods: {
    closeConsent(result) {
      if (result === true) {
        this.$bvModal.hide('termsAndConditionsModal')
      } else {
        this.$bvModal.hide('termsAndConditionsModal')
        this.$router.push({ name: 'member-home' })
      }
    },
    validationForm() {
      this.$swal({
        title: 'Are you sure?',
        text: `${this.$t('title.Confirm Connect Customer')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // this.newAccount()
          if (this.accountLoginData.phone === this.ptPhone) {
            this.newAccount()
            // console.log('New Account')
          } else {
            // console.log('Sent OTP')
            this.checkOTPState = true
            this.sendOTP()
          }
        }
      })
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    sendOTP() {
      this.$http({
        url: `http://phrapi.sriphat.com/api/UserAuthen/OTPSending/${this.ptPhone}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        // console.log(data)
        this.countDown = 60
        this.countDownTimer()
      })
    },
    validationOTP() {
      if (this.OTPValue.length === 6) {
        this.validationState = true
        this.$http({
          url: 'http://phrapi.sriphat.com/api/UserAuthen/OTPChecker',
          method: 'POST',
          data: {
            PhoneNumber: this.ptPhone,
            OTPCode: this.OTPValue,
          },
        }).then(({ data }) => {
          if (data) {
            this.newAccount()
          } else {
            this.$swal('Error')
          }
        })
      } else {
        console.log('Show Alert Fill OTP')
      }
      this.validationState = false
    },
    handleOnComplete(value) {
      this.OTPValue = value
    },
    handleOnChange(value) {
      this.OTPValue = value
    },
    newAccount() {
      // this.loginAgain()
      const hnValue = `0000000${this.hnValue}`.substr(-7)
      this.$http({
        url: 'http://phrapi.sriphat.com/api/UserAuthen/NewAccount',
        // url: 'http://192.168.100.56/api/UserAuthen/NewAccount',
        method: 'POST',
        data: {
          customer_Account: {
            accountID: 0,
            customerID: this.customerID,
            Username: this.accountLoginData.phone,
            hn: hnValue,
            accountPassword: this.accountLoginData.psw,
          },
          customer_Account_Connect: {
            accountConnectID: 0,
            accountID: 0,
            userTokenID: this.userData.userId,
            typeConnect: 'LINE',
          },
        },
      }).then(({ data }) => {
        this.$ability.update([{ action: 'manage', subject: 'all' }])
        const patientTempData = {
          idCard: this.idCardValue,
        }
        localStorage.setItem('patientTempData', JSON.stringify(patientTempData))
        this.approveConsent()
        // this.loginAgain()
      }).catch(error => {
        // console.log(error)
        this.$refs.bindingForm.setErrors(error)
      })
      this.loginAgain()
    },
    getPatientInfo() {
      if (this.idCardValue !== '') {
        this.$http({
          // url: 'http://api159.sriphat.com/api/LoginPatient/login-Employee',
          url: `http://phrapi.sriphat.com/api/Patient/GetPatientDetail/${this.idCardValue}/null`,
          method: 'GET',
          data: { },
        }).then(({ data }) => {
          if (data.length > 0 && this.idCardValue === data[0].idCard) {
            this.checkProfile = true
            // eslint-disable-next-line prefer-destructuring
            this.patientData = data[0]
            this.ptName = `${data[0].firstName} ${data[0].lastName}`
            this.hnValue = data[0].hn
            this.customerID = data[0].customerID
            this.ptPhone = data[0].mainContactNumber.trim()
            this.ptPhone = this.ptPhone.replace('-', '')
          } else {
            this.checkProfile = false
            this.$swal({
              title: 'ไม่พบข้อมูล',
              text: 'กรุณาตรวจสอบหมายเลขที่กรอกอีกครั้ง',
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-outline-primary',
              },
              buttonsStyling: false,
            })
          }
        }).catch(error => {
          console.log('getPatientInfo', error)
          this.$refs.bindingForm.setErrors(error)
        })
      }
    },
    loginAgain() {
      this.$http({
        url: 'http://phrapi.sriphat.com/api/UserAuthen/Login',
        method: 'POST',
        data: {
          UserID: '',
          Username: this.accountLoginData.phone,
          HN: '',
          Password: this.accountLoginData.psw,
          TypeLogin: 'NM',
        },
      }).then(({ data }) => {
        const userRole = 'admin'
        const userData = {
          id: 1,
          fullName: this.ptName,
          username: this.accountLoginData.phone,
          avatar: '',
          email: `${this.accountLoginData.phone}@line.com`,
          role: userRole,
          ability: [{ action: 'manage', subject: 'all' }],
          extras: { },
        }
        // let checkBinding = false
        // console.log('patientData', data)
        this.$ability.update(userData.ability)
        localStorage.setItem('accountData', JSON.stringify(data))
        localStorage.setItem('patientData', JSON.stringify(this.patientData))
        useJwt.setToken(data.token)
        useJwt.setRefreshToken(data.token)
        localStorage.setItem('userData', JSON.stringify(userData))
        this.$router.replace(getHomeRouteForLoggedInUser(userRole))
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Welcome ${userData.fullName || userData.username}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
              },
            })
          })
          .catch(error => {
            console.log('LoginAgainChangePage', error)
          })
      }).catch(error => {
        // console.log(error)
        console.log('LoginAgain', error)
      })
    },
    approveConsent() {
      this.$http({
        url: `http://phrapi.sriphat.com/api/Patient/CheckConsentPatient/${this.hnValue}/PDPA`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        // eslint-disable-next-line prefer-destructuring
        const customerID = data.customerID
        if (!data.consentFlag) {
          this.$http({
            url: 'http://phrapi.sriphat.com/api/Patient/NewConsent',
            method: 'POST',
            data: {
              CustomerID: customerID,
              ConsentFlag: true,
              ConsentType: 'PDPA',
              CreateBy: 'Register',
              HN: this.hnValue,
            },
          }).then(() => {
          }).catch(error => {
            console.log('approveConsent-NewConsent', error)
          })
        }
      }).catch(error => {
        console.log('approveConsent', error)
      })
    },
  },
}
</script>
